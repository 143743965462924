import React, { useState, useEffect } from 'react';

import User from '../../components/user/User'
import Layout from "../../components/layout"


const Index = () => {


    return (
        <Layout>
            <User />
        </Layout>
    )
}

export default Index
