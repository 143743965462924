import React from "react";
import { Button, Dropdown } from "semantic-ui-react";
import { Field, reduxForm } from "redux-form";
import { Auth } from "aws-amplify";
import { connect } from "react-redux";
import { updateUserInfo, fetchTrusts } from "../../actions";

class User extends React.Component {
  constructor(props) {
    super(props);
    this.state = { userData: this.props.user };
  }

  componentDidMount() {
    this.props.fetchTrusts();
  }

  renderError({ error, touched }) {
    if (touched && error) {
      return (
        <div className="ui error message">
          <div className="header">{error}</div>
        </div>
      );
    }
  }

  rederInput = ({ input, label, type, meta }) => {
    const className = `field ${meta.error && meta.touched ? "error" : ""}`;
    return (
      <div className={className}>
        <label>{label}</label>
        <input {...input} placeholder={label} autoComplete="off" type={type} />
        {this.renderError(meta)}
      </div>
    );
  };

  renderDropdown = ({ input, label, type, meta }) => {
    // if (this.props.user["custom:level"] !== "admin") {
    //   return <div></div>;
    // }
    return (
      <div className="field">
        <label>{label}</label>
        <Dropdown
          selection
          {...input}
          options={this.props.trusts.map((trust) => ({
            key: trust.trust_id,
            value: trust.trust_id,
            text: trust.name,
          }))}
          value={input.value}
          onChange={(param, data) => input.onChange(data.value)}
          placeholder={label}
        ></Dropdown>
      </div>
    );
  };

  onSubmit = async (formValues) => {
    console.log(formValues);

    try {
      let user = await Auth.currentAuthenticatedUser();
      console.log(user);
      let result = await Auth.updateUserAttributes(user, {
        //given_name: formValues.given_name,
        //family_name: formValues.family_name,
        "custom:trust": formValues.trust,
      });
      console.log(result);
    } catch (e) {
      alert(e.message);
    }
    let user = await Auth.currentAuthenticatedUser({ bypassCache: true });
    this.props.updateUserInfo(user.attributes);
  };

  render() {
    return (
      <div>
        <button onClick={() => console.log(this.props.user)}>User</button>
        <form
          onSubmit={this.props.handleSubmit(this.onSubmit)}
          className="ui form"
        >
          <Field
            name="given_name"
            label="First Name"
            component={this.rederInput}
            type="text"
          />
          <Field
            name="family_name"
            label="Last Name"
            component={this.rederInput}
            type="text"
          />
          <Field
            name="trust"
            label="Trust"
            component={this.renderDropdown}
            type="text"
          />
          <p>
            Your access level is set to{" "}
            <strong>{this.state.userData["custom:level"]}</strong>
          </p>
          <Button primary>Update</Button>
        </form>
      </div>
    );
  }
}

const validate = (formValues) => {
  const errors = {};
  if (!formValues.email) {
    errors.email = "You must enter an email";
  }
  if (!formValues.password) {
    errors.password = "You must enter a password";
  }
  return errors;
};

const mapStateToProps = (state) => {
  const given_name = state.user.given_name;
  const family_name = state.user.family_name;
  const trust = state.user["custom:trust"];
  return {
    user: state.user,
    initialValues: {
      given_name: given_name,
      family_name: family_name,
      trust: trust,
    },
    trusts: state.trusts,
  };
};

User = reduxForm({
  form: "user",
  validate: validate,
  enableReinitialize: true,
})(User);

User = connect(mapStateToProps, { updateUserInfo, fetchTrusts })(User);

export default User;
